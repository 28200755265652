import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout/Layout";
import SEO from "../components/seo/Seo";
import Page from "../components/page/Page";
import i18nContext from "../components/i18n/i18n.context";
import Tutorials from "../components/tutorials/Tutorials";

function TutorialsPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "en" }}>
      <Layout>
        <SEO
          keywords={["gatsby", "react"]}
          location={location}
          metaDescription="Interactive maps for your business: integrate maps and geocoding in your websites and mobile apps."
          title="Tutorials"
        />
        <Page>
          <Tutorials />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

TutorialsPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default TutorialsPage;
