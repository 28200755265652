import styles from "./Tutorials.module.scss";

import React from "react";
import PropTypes from "prop-types";

import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import i18n from "./Tutorials.i18n.json";

import addI18nContextConsumer from "../i18n/addI18nContextConsumer";

function Tutorials({ strings, locale }) {
  const data = useStaticQuery(graphql`
    query {
      logoBigcommerce: file(
        relativePath: { eq: "Logos-platform/Bigcommerce.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 85, formats: [AUTO, WEBP, AVIF])
        }
      }
      logoWeebly: file(relativePath: { eq: "Logos-platform/Weebly.png" }) {
        childImageSharp {
          gatsbyImageData(height: 85, formats: [AUTO, WEBP, AVIF])
        }
      }
      logoMagento: file(relativePath: { eq: "Logos-platform/Magento.png" }) {
        childImageSharp {
          gatsbyImageData(height: 85, formats: [AUTO, WEBP, AVIF])
        }
      }
      logoShopify: file(relativePath: { eq: "Logos-platform/Shopify.png" }) {
        childImageSharp {
          gatsbyImageData(height: 85, formats: [AUTO, WEBP, AVIF])
        }
      }
      logoSquare: file(relativePath: { eq: "Logos-platform/Square.png" }) {
        childImageSharp {
          gatsbyImageData(height: 85, formats: [AUTO, WEBP, AVIF])
        }
      }
      logoSquarespace: file(
        relativePath: { eq: "Logos-platform/Squarespace.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 85, formats: [AUTO, WEBP, AVIF])
        }
      }
      logoWix: file(relativePath: { eq: "Logos-platform/Wix.png" }) {
        childImageSharp {
          gatsbyImageData(height: 85, formats: [AUTO, WEBP, AVIF])
        }
      }
      logoWoocommerce: file(
        relativePath: { eq: "Logos-platform/Woocommerce.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 85, formats: [AUTO, WEBP, AVIF])
        }
      }
      logoWordpress: file(
        relativePath: { eq: "Logos-platform/Wordpress.png" }
      ) {
        childImageSharp {
          gatsbyImageData(height: 85, formats: [AUTO, WEBP, AVIF])
        }
      }
      logoWeebly: file(relativePath: { eq: "Logos-platform/Weebly.png" }) {
        childImageSharp {
          gatsbyImageData(height: 85, formats: [AUTO, WEBP, AVIF])
        }
      }
      logoJoomla: file(relativePath: { eq: "Logos-platform/Joomla.png" }) {
        childImageSharp {
          gatsbyImageData(width: 253, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);

  const LIST = [
    [
      {
        name: "Shopify",
        img: data.logoShopify,
        link: "shopify",
      },
      {
        name: "Weebly",
        img: data.logoWeebly,
        link: "weebly",
      },
      {
        name: "BigCommerce",
        img: data.logoBigcommerce,
        link: "bigcommerce",
      },
    ],
    [
      {
        name: "WordPress",
        img: data.logoWordpress,
        link: "wordpress",
      },
      {
        name: "Magento",
        img: data.logoMagento,
        link: "magento",
      },
      { name: "Wix", img: data.logoWix, link: "wix" },
    ],
    [
      {
        name: "Square",
        img: data.logoSquare,
        link: "square",
      },
      {
        name: "Squarespace",
        img: data.logoSquarespace,
        link: "squarespace",
      },
      {
        name: "WooCommerce",
        img: data.logoWoocommerce,
        link: "wordpress",
      },
    ],
    [
      {
        name: "Joomla!",
        img: data.logoJoomla,
        link: "joomla",
      },
    ],
  ];

  return (
    <div className={styles["container"]}>
      <div className={styles["bg_container"]} />
      <div className={styles["content"]}>
        <h1>{strings["title"]}</h1>
        <h2>{strings["subtitle"]}</h2>
        <div className={styles["tutorials"]}>
          {LIST.map((l) => (
            <div className={styles["tutorials_row"]}>
              {l.map((t) => (
                <div className={styles["tutorial"]}>
                  <div className={styles["image"]}>
                    <Link
                      className={`Link-Tutorials-${t.name}`}
                      to={`/${locale}/integration/${t.link}`}
                    >
                      <GatsbyImage image={getImage(t.img)} />
                    </Link>
                  </div>
                  <div className={styles["text_container"]}>
                    <div className={styles["text"]}>{strings["integrate"]}</div>
                    <div className={styles["link"]}>
                      <Link
                        className={`Link-Tutorials-${t.name}`}
                        to={`/${locale}/integration/${t.link}`}
                      >
                        {t.name}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

Tutorials.propTypes = {
  locale: PropTypes.string.isRequired,
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(Tutorials);
